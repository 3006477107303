









import { Component, Prop, Vue } from 'vue-property-decorator';

import PolicyConfirmShowConfirm from '@/components/PolicyConfirmShowConfirm.vue';

@Component({
  components: {
    PolicyConfirmShowConfirm
  }
})
export default class PolicyConfirmShowTemplate extends Vue {

  @Prop({ required: true})
  dialog!: boolean;

  @Prop({ required: true})
  agree!: () => void;

  @Prop({ required: true})
  message!: string;

  @Prop({ required: true})
  leave!: () => void;

}
