









import { Component, Inject, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import  Config  from '@/api/models/config';
import Policy from '@/api/models/policy';
import PolicyConfirmShowTemplate from '@/components/PolicyConfirmShowTemplate.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { GlobalMessageModule } from '@/store/global_message';;

@Component({
  components: {
    PolicyConfirmShowTemplate
  }
})
export default class PolicyConfirmShow extends Vue {
  @Inject()
  api!: Api;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Inject()
  globalMessageModule!: GlobalMessageModule;

  dialog: boolean = true;

  message: string ='';
  
  policy!: Policy;

  config!: Config;

  active: boolean = true;

  async created() {
    await this.globalLoadingScreenModule.track(
      this.getPolicy(),
      this.getConfig()
    );
    this.message = this.policy.message;
  }

  async getPolicy() {
    this.policy = await this.api.getPolicy();
  }

  async getConfig() {
    this.config = await this.api.getConfig();
  }

  async onAgree() {
    if (!this.active) return; 
    this.active = false;
    try {
      await this.globalLoadingScreenModule.track(this.api.putPolicy());
      window.location.href = '/';
    } catch (error) {
      this.globalMessageModule.addMessages('error', error.modelMessages());
    } finally {
      this.active = true;
    }
  }

  onLeave() {
    window.location.href = this.config.userServicesUrl;
  }
}
